import React from 'react'
import { Twitter, Github, Linkedin, Instagram, Mail } from 'lucide-react';
import { faTwitter, faLinkedin, faGithub, faMedium } from '@fortawesome/free-brands-svg-icons';
import Navbar from '../components/navBar'
import SocialLink from '../components/SocialLink';
import Footer from '../components/Footer';

export default function About() {

    const skills = ['Java','Machine Learning','Agile','Swift', 'Python', 'Node JS', 'JavaScript', 'React', 'Wordpress', 'HTML', 'CSS', 'GIT', 'Github', 'MongoDB', 'Docker', 'MySql', 'Kotlin'];

return (
        <div>
            <Navbar />
            <div className="max-w-4xl mx-auto p-4">
                <section className="mb-12 mt-32 ">
                    <h1 className="text-4xl font-bold mb-4 pt-18">I'm Olawale Filani, I live in Coventry, UK. Where I develop the future.</h1>
                    <p className="text-lg text-gray-600 mb-6">
                    I've worked on a variety of projects over the years and I'm proud of the progress I've made. Many of these projects are open-source and available for others to explore and contribute to. If you're interested in any of the projects I've worked on, please feel free to check out the code and suggest any improvements or enhancements you might have in mind. Collaborating with others is a great way to learn and grow, and I'm always open to new ideas and feedback.
                    </p>
                    <div className="mt-8 sm:mt-12 md:fixed md:left-6 md:top-[45%] md:transform md:-translate-y-1/2 flex flex-row md:flex-col space-x-5 md:space-x-0 md:space-y-7">
                        <SocialLink href="#" icon={faTwitter} />
                        <SocialLink href="#" icon={faLinkedin} />
                        <SocialLink href="https://github.com/olawale021" icon={faGithub} />
                        <SocialLink href="#" icon={faMedium} />
                    </div>
                    <a href="mailto:olawale@ola-hub.com" className="flex items-center pt-10 text-gray-600 hover:text-blue-500">
                    <Mail size={24} className="mr-2" />
                    olawale@ola-hub.com
                    </a>
                </section>

                <section>
                    <h2 className="text-3xl font-bold mb-6">My Skills</h2>
                    <div className="flex flex-wrap gap-3">
                    {skills.map((skill, index) => (
                        <span key={index} className="bg-gray-200 text-gray-700 px-3 py-1 rounded-full text-sm">
                        {skill}
                        </span>
                    ))}
                    </div>
                </section>
            </div>
            <Footer />
        </div>
    )
}
