import React, { useState } from 'react';
import { Github, Linkedin, Mail, Twitter, ArrowUp } from 'lucide-react';

const Footer = () => {
    const [email, setEmail] = useState('');
    const currentYear = new Date().getFullYear();

    const handleSubscribe = (e) => {
        e.preventDefault();
        // Here should typically send the email to backend
        console.log(`Subscribed with email: ${email}`);
        setEmail('');
    };

    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    return (
        <footer className="bg-gray-50 text-gray-600 py-12 border-t border-gray-200">
        <div className="container mx-auto px-4">
            <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
            <div className="col-span-1 md:col-span-2">
                <h2 className="text-2xl font-semibold text-gray-800 mb-4">OLAHUB</h2>
                <p className="text-sm mb-6">Elevating digital experiences through innovative web solutions and cutting-edge technology.</p>
                <div className="flex space-x-4">
                <a href="https://github.com/olawale021" target="_blank" rel="noopener noreferrer" className="text-gray-400 hover:text-gray-600 transition-colors">
                    <Github size={20} />
                </a>
                <a href="https://linkedin.com/in/olahub" target="_blank" rel="noopener noreferrer" className="text-gray-400 hover:text-gray-600 transition-colors">
                    <Linkedin size={20} />
                </a>
                <a href="https://twitter.com/olahub" target="_blank" rel="noopener noreferrer" className="text-gray-400 hover:text-gray-600 transition-colors">
                    <Twitter size={20} />
                </a>
                <a href="mailto:contact@olahub.com" className="text-gray-400 hover:text-gray-600 transition-colors">
                    <Mail size={20} />
                </a>
                </div>
            </div>
            <div>
                <h3 className="text-lg font-semibold text-gray-800 mb-4">Quick Links</h3>
                <ul className="space-y-2 text-sm">
                <li><a href="#projects" className="hover:text-gray-800 transition-colors">Projects</a></li>
                <li><a href="#about" className="hover:text-gray-800 transition-colors">About</a></li>
                <li><a href="#services" className="hover:text-gray-800 transition-colors">Services</a></li>
                <li><a href="#contact" className="hover:text-gray-800 transition-colors">Contact</a></li>
                </ul>
            </div>
            <div>
                <h3 className="text-lg font-semibold text-gray-800 mb-4">Stay Updated</h3>
                <form onSubmit={handleSubscribe} className="space-y-2">
                <input 
                    type="email" 
                    value={email} 
                    onChange={(e) => setEmail(e.target.value)} 
                    placeholder="Your email" 
                    className="w-full px-3 py-2 text-sm bg-white border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                    required
                />
                <button type="submit" className="w-full px-4 py-2 text-sm text-white bg-blue-600 rounded-md hover:bg-blue-700 transition-colors focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2">
                    Subscribe
                </button>
                </form>
            </div>
            </div>
            <div className="mt-12 pt-8 border-t border-gray-200 flex flex-col md:flex-row justify-between items-center">
            <p className="text-sm">&copy; {currentYear} Olahub. All rights reserved.</p>
            <button onClick={scrollToTop} className="mt-4 md:mt-0 flex items-center text-sm text-gray-500 hover:text-gray-700 transition-colors focus:outline-none">
                Back to top <ArrowUp size={16} className="ml-1" />
            </button>
            </div>
        </div>
        </footer>
    );
};

export default Footer;