import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const SocialLink = ({ href, icon }) => (
    <a
        href={href}
        className="w-12 h-12 flex items-center justify-center bg-gray-200 text-gray-600 rounded-full hover:bg-purple-600 hover:text-white transition duration-300"
        target="_blank"
        rel="noopener noreferrer"
    >
        <FontAwesomeIcon icon={icon} size="lg" />
    </a>
);

export default SocialLink;