import React from 'react';
import { useNavigate } from 'react-router-dom';
import Navbar from '../components/navBar';
import Footer from '../components/Footer';
import SocialLink from '../components/SocialLink';
import { faTwitter, faLinkedin, faGithub, faMedium } from '@fortawesome/free-brands-svg-icons';

export default function Projects() {
    const navigate = useNavigate();
    // projects data
    const projects = [
        { id: 1, title: "SWAP-EX - An item exchange Platform", description: "A full-stack e-commerce solution with Java EE and SQLite with AI integration to predict categories.", imageUrl: "/swapex.png" },
        { id: 2, title: "Android Food Delivery Application", description: "A full-stack food delivery app built with Kotlin for Android, utilizing Room Database.", imageUrl: "/delivery.png" },
        { id: 3, title: "Android Food Recipe Application", description: "A recipe app developed with Kotlin for Android, leveraging Room Database to manage and store recipes locally.", imageUrl: "/recipe.png" },
        { id: 4, title: "StepCare - Appointment Booking System ", description: "A full-stack healthcare appointment booking system built with Python and Flask, utilizing MongoDB for efficient data management.", imageUrl: "/stepcare.png" },
        { id: 5, title: "E-commerce", description: "A full-stack e-commerce platform built with React, MongoDB, Express, and Node.js, designed for efficient data management and seamless user experiences.", imageUrl: "/ecommerce.png" },
        { id: 6, title: "Event Ticket Management", description: "Under Construction", imageUrl: "/api/placeholder/250/150" },
    ];

    const navigateToProjectDetails = (projectId) => {
        navigate(``);
    };

    return (
        <div className="min-h-screen flex flex-col bg-gray-100">
            <Navbar />
            
            <main className="flex-grow container mx-auto px-4 py-12 max-w-6xl">
                <h1 className="text-3xl font-bold mb-12 text-center text-gray-800">Personal Projects</h1>
                
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8 justify-items-center">
                    {projects.map((project) => (
                        <div 
                            key={project.id}
                            className="bg-white shadow-md rounded-lg overflow-hidden cursor-pointer transform transition duration-300 hover:scale-105 hover:shadow-lg w-full max-w-sm"
                            onClick={() => navigateToProjectDetails(project.id)}
                        >
                            <img
                                src={project.imageUrl}
                                alt={project.title}
                                className="w-full h-48 object-cover"
                            />
                            <div className="p-4">
                                <h2 className="text-lg font-semibold mb-2 text-gray-800">{project.title}</h2>
                                <p className="text-sm text-gray-600 mb-2 line-clamp-2">{project.description}</p>
                                <button className="text-sm text-blue-500 hover:text-blue-600 font-medium">
                                    View details →
                                </button>
                            </div>
                        </div>
                    ))}
                </div>
            </main>

            <div className="fixed left-4 top-1/2 transform -translate-y-1/2 hidden lg:flex flex-col space-y-3">
                <SocialLink href="#" icon={faTwitter} />
                <SocialLink href="#" icon={faLinkedin} />
                <SocialLink href="#" icon={faGithub} />
                <SocialLink href="#" icon={faMedium} />
            </div>

            <Footer />
        </div>
    );
}
