import React from 'react';
import Navbar from '../components/navBar';
import { faTwitter, faLinkedin, faGithub, faMedium } from '@fortawesome/free-brands-svg-icons';
import SocialLink from '../components/SocialLink';

import './styles/homepage.css';
import MostRecentProjects from '../components/MostRecentProjects';
import RecentArticles from '../components/RecentArticles';
import Footer from '../components/Footer';

function Homepage() {
  return (
    <div>
    <div className="min-h-screen bg-cover bg-center bg-no-repeat flex flex-col font-sans relative" style={{backgroundImage: "url('/d1.png')"}}>
      <div className="absolute inset-0 bg-pattern opacity-50"></div>
      <div className="relative z-10 flex flex-col min-h-screen">
        <Navbar />
        <main className="flex-grow flex flex-col items-center justify-start pt-16 sm:pt-20 lg:pt-32 px-4 sm:px-6 lg:px-8">
          <div className="max-w-3xl w-full text-center mt-8 sm:mt-12 md:mt-20 lg:mt-28 xl:mt-36">
            <h1 className="text-4xl sm:text-5xl md:text-6xl font-bold mb-6 text-gray-800">
              HEY, I'M OLAWALE FILANI
            </h1>
            <p className="text-lg sm:text-xl mb-8 text-gray-600 leading-relaxed max-w-2xl mx-auto">
              A Software Engineer with a passion for building impactful mobile and web applications.
              I also specialize in integrating advanced AI models to create user-friendly solutions that help businesses meet their clients expectations.
              Whether start ups or existing businesses, I combine the latest technology with thoughtful design to develop applications that meet and exceed expectations.
            </p>
            <button className="bg-purple-600 text-white px-8 py-3 rounded-full font-semibold hover:bg-purple-700 transition duration-300 transform hover:scale-105 shadow-lg uppercase tracking-wide">
              Recent Projects
            </button>
          </div>
          <div className="mt-8 sm:mt-12 md:fixed md:left-6 md:top-[45%] md:transform md:-translate-y-1/2 flex flex-row md:flex-col space-x-5 md:space-x-0 md:space-y-7">
            <SocialLink href="#" icon={faTwitter} />
            <SocialLink href="#" icon={faLinkedin} />
            <SocialLink href="https://github.com/olawale021" icon={faGithub} />
            <SocialLink href="#" icon={faMedium} />
          </div>
        </main>
      </div>
    </div>
    <MostRecentProjects />
    <RecentArticles />
    <Footer />
    </div>
  );
}

export default Homepage;