    import React from 'react';
    import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
    import { useNavigate } from 'react-router-dom';
    import { faInfoCircle, faArrowRight } from '@fortawesome/free-solid-svg-icons';
    import './styles/MostRecentProject.css';

    const MostRecentProjects = () => {
        const navigate = useNavigate();
    
        const projects = [
            {
                id: 1,
                title: "SWAP-EX - An item exchange Platform",
                description: "A full-stack e-commerce solution with Java EE and SQLite with AI integration to predict categories.",
                image: "/swapex.png",
                detailsLink: "/projects"
            },
            {
                id: 2,
                title: "Android Food Delivery Application",
                description: "A full-stack food delivery app built with Kotlin for Android, utilizing Room Database.",
                image: "/delivery.png",
                detailsLink: "/projects"
            },
            {
                id: 3,
                title: "Android Food Recipe Application",
                description: "A recipe app developed with Kotlin for Android, leveraging Room Database to manage and store recipes locally.",
                image: "/recipe.png",
                detailsLink: "/projects"
            }
        ];
    
        return (
            <section className="py-16 bg-gray-100 font-body">
                <div className="container mx-auto px-4">
                    <h2 className="text-3xl font-bold text-center mb-12 font-heading">Most Recent Projects</h2>
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                        {projects.map((project) => (
                            <div key={project.id} className="bg-white rounded-lg shadow-md overflow-hidden">
                                <img src={project.image} alt={project.title} className="w-full h-48 object-cover" />
                                <div className="p-6">
                                    <h3 className="text-xl font-semibold mb-2 font-heading">{project.title}</h3>
                                    <p className="text-gray-600 mb-4">{project.description}</p>
                                    <div className="flex justify-center">
                                        <button
                                            onClick={() => navigate(project.detailsLink)}
                                            className="text-purple-600 hover:text-purple-700 transition duration-300 flex items-center font-medium"
                                        >
                                            <FontAwesomeIcon icon={faInfoCircle} className="mr-2" />
                                            Project Details
                                        </button>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className="bg-gray-100 py-12 text-center">
                        <button
                            onClick={() => navigate('/projects')}
                            className="inline-flex items-center text-purple-600 hover:text-purple-700 text-lg font-semibold transition duration-300 font-heading"
                        >
                            View All Projects
                            <FontAwesomeIcon icon={faArrowRight} className="ml-2" />
                        </button>
                    </div>
                </div>
            </section>
        );
    };
    
    export default MostRecentProjects;
    