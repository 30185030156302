import React from 'react';
import { Link } from 'react-router-dom';

const RecentArticles = () => {
  // Sample article data
    const articles = [
        {
            id: 1,
            title: "Introduction to Machine Learning Algorithms",
            excerpt: "Explore the basics of machine learning algorithms and their applications in data science...",
            date: "September 5, 2024",
            readTime: "7 min read"
        },
        {
        id: 2,
        title: "Building Scalable Java Applications",
        excerpt: "Discover best practices for creating scalable and maintainable Java apps...",
        date: "June 2, 2024",
        readTime: "7 min read"
        },
        {
        id: 3,
        title: "Machine Learning in Python: A Beginner's Guide",
        excerpt: "Get started with machine learning using Python and popular libraries...",
        date: "June 20, 2024",
        readTime: "10 min read"
        }
    ];

    return (
        <section className="py-12 bg-gray-50">
        <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
            <h2 className="text-3xl font-bold text-center mb-8 text-gray-800">Recent Articles</h2>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            {articles.map((article) => (
                <article key={article.id} className="bg-white rounded-lg shadow-md overflow-hidden">
                <div className="p-6">
                    <h3 className="font-semibold text-xl mb-2 text-gray-800">{article.title}</h3>
                    <p className="text-gray-600 mb-4">{article.excerpt}</p>
                    <div className="flex justify-between items-center text-sm text-gray-500">
                    <span>{article.date}</span>
                    <span>{article.readTime}</span>
                    </div>
                </div>
                </article>
            ))}
            </div>
            <div className="text-center mt-8">
            <Link to="/articles" className="inline-block bg-purple-600 text-white px-6 py-2 rounded-full font-semibold hover:bg-purple-700 transition duration-300">
                View All Articles
            </Link>
            </div>
        </div>
        </section>
    );
};

export default RecentArticles;